import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import NewsTitlesBulletLine from "../BulletLine/NewsTitlesBulletLine";
import "./HomePage.css";
import Banner from "../Ads/threeDAds";
import { useTheme } from "../Theme/ThemeContext";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer"; 

// Skeleton Loader Component
const NewsCardSkeleton = React.memo(() => (
  <div className="news-block skeleton">
    <div className="skeleton-image"></div>
    <div className="skeleton-details">
      <div className="skeleton-title"></div>
      <div className="skeleton-text short"></div>
      <div className="skeleton-text long"></div>
      <div className="skeleton-text small"></div>
    </div>
  </div>
));

const NewsCard = React.memo(({ article }) => {
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      // Fetch additional data or perform actions when card becomes visible
    }
  }, [inView]);

  const handleNewsClick = () => {
    window.scrollTo(0, 0);
    navigate(`/news/${article._id}`);
  };

  const fadeIn = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "scale(1)" : "scale(0.95)",
    config: { duration: 300 },
  });

  return (
    <animated.div
      ref={ref}
      style={fadeIn}
      className="news-card"
      loading="lazy"
    >
      <div onClick={handleNewsClick} role="button" tabIndex={0} onKeyPress={handleNewsClick}>
        {article.images && article.images.length > 0 && (
          <div className="watermark-container">
            <img
              src={article.images[0]}
              alt={article.title}
              loading="lazy"
              className="card-image"
            />
            <div className="watermark-overlay">News Day Rajasthan</div>
          </div>
        )}
        <div className="card-details">
          <h2 className="card-title">{article.title}</h2>
          <h6 className="card-date">
            {moment(article.date)
              .subtract(5, "hours")
              .subtract(30, "minutes")
              .format("DD-MM-YYYY")}
          </h6>
          <h6 className="card-location">
            {article.city || "चित्तौड़गढ़"} ,{article.state}
          </h6>
          <span className="read-more">...पढ़ना जारी रखे</span>
        </div>
      </div>
    </animated.div>
  );
});

const HomePage = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { theme } = useTheme();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get("/api/news/all");
        const sortedNews = response.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setNews(sortedNews);
        setLoading(false);
      } catch (error) {
        console.error("There was an error fetching the news!", error);
        setError(true);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  return (
    <div
      className={`container ${theme === "dark" ? "dark-mode" : ""}`}
      onContextMenu={(e) => e.preventDefault()}
      style={{ userSelect: "none" }}
    >
      <br />
      <br />
      <NewsTitlesBulletLine news={news} />
      <br />
      <div className="banner-section">
        <Banner />
      </div>
      <br />
      <div className="breaking-news">
        <div className="box-with-animation">
        <h1 style={{ color: "red" }}>ताजा खबरे</h1>
        </div>
      </div>
      {error ? (
        <div className="error-message">Error fetching news. Please try again later.</div>
      ) : (
        <div className="news-block">
          {loading
            ? Array.from({ length: 12 }).map((_, index) => (
              <div key={index}>
                <NewsCardSkeleton />
              </div>
            ))
            : news.map((article) => (
              <NewsCard key={article._id} article={article} />
            ))}
        </div>
      )}
    </div>
  );
};

export default HomePage;
