import React from 'react';// Assuming truncateText is imported from a utils file

const share = async (article) => {
  if (navigator.share) {
    try {
      const shareData = {
        title: article.title || "Check out this news article!",
        text:
          `${article.title}\n ${article.city}\n पढ़ें पूरी खबर 👉 ` ||
          "Check out this news article!",
        url: `${window.location.origin}/news/${article._id}`,
      };
      await navigator.share(shareData);
    } catch (error) {
      console.error("Error sharing:", error);
      // alert('Failed to share the article.');
    }
  } else {
    alert("Sharing not supported on this browser.");
  }
};



export default share;
