import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import NewsTableSkeleton from "./NewsTableSkeleton.js";
import { useTheme } from "../Theme/ThemeContext";

const ApprovedNews = ({ handleViewDetails }) => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const { theme } = useTheme();

  useEffect(() => {
    fetchAllNews();
  }, []);

  const fetchAllNews = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/news/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedNews = response.data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setNews(sortedNews);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching all news:", error);
    }
  };

  const handleDeleteNews = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this news article?");
    if (!confirmed) return;

    try {
      const token = localStorage.getItem("token");
      await axios.delete(`/api/news/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedNews = news.filter((article) => article._id !== id);
      setNews(updatedNews);
    } catch (error) {
      console.error("Error deleting news:", error);
    }
  };

  return (
    <div>
      <h2>All Approved News</h2>
      {loading ? (
        <Table striped bordered hover className={theme === "dark" ? "table-dark" : ""}>
          <thead>
            <tr>
              <th>Article details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <NewsTableSkeleton />
            <NewsTableSkeleton />
            <NewsTableSkeleton />
          </tbody>
        </Table>
      ) : (
        <Table striped bordered hover className={theme === "dark" ? "table-dark" : ""}>
          <thead>
            <tr>
              <th>Article details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {news.map((article) => (
              <tr key={article._id}>
                <td onClick={() => handleViewDetails(article)}>
                  <h5>{article.title}</h5>
                  <h6 style={{ color: 'red' }}>{article.views} views</h6>
                  <p>{article.city}, {article.state}</p>
                  <p>{article.reporter.username}</p>
                  <p>{moment(article.date).subtract(5, 'hours').subtract(30, 'minutes').format("DD-MM-YYYY, h:mm A")}</p>
                </td>
                <td>
                  <Button variant="danger" onClick={() => handleDeleteNews(article._id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ApprovedNews;
