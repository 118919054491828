import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './3d.css';

const AdList = () => {
  const [ads, setAds] = useState([]);
  const imgContainerRef = useRef(null);

  useEffect(() => {
    axios.get('/api/ads/all')
      .then(response => {
        setAds(response.data);
      })
      .catch(error => {
        console.error('Error fetching ads:', error);
      });
  }, []);

  useEffect(() => {
    const imgContainer = imgContainerRef.current;
    if (imgContainer && ads.length > 0) {
      const interval = setInterval(() => {
        const firstChild = imgContainer.firstElementChild;
        if (firstChild) {
          imgContainer.appendChild(firstChild);
        }
      }, 2000);

      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [ads]);

  return (
    <div className="img-container" ref={imgContainerRef}>
      {ads.map((ad, index) => (
        <div className="box" key={index}>
          <a href={ad.link} target="_blank" rel="noopener noreferrer">
            <img className="ad-img" src={Array.isArray(ad.images) ? ad.images[0] : ad.images} alt="ad" />
          </a>
        </div>
      ))}
    </div>
  );
};

export default AdList;
