import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../Theme/ThemeContext';
import './NewsTitlesBulletLine.css';

const NewsTitlesBulletLine = ({ news }) => {
    const { theme } = useTheme();

    // Helper function to check if the date is within the last 24 hours
    const isWithinLast24Hours = (date) => {
        const now = new Date();
        const newsDate = new Date(date);
        const hoursDifference = (now - newsDate) / (1000 * 60 * 60);

        return hoursDifference <= 24;
    };

    // Filter the news array to include only news from the last 24 hours
    const last24HoursNews = news ? news.filter(article => isWithinLast24Hours(article.date)) : [];

    if (last24HoursNews.length === 0) {
        return null; // Return null if there are no news articles from the last 24 hours
    }

    return (
        <div className={`news-titles-bullet-line ${theme === 'dark' ? 'dark-mode' : ''}`}>
        <br/>
        <br/>
            <div className="title"><h6>प्रमुख समाचार</h6></div>
            <div className="bullet">
                {last24HoursNews.slice(0, 9).map((article) => (
                    <Link key={article._id} to={`/news/${article._id}`} className="ticker-title">
                        <h6>{article.title}</h6>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default NewsTitlesBulletLine;
