import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaYoutube, FaWhatsapp } from 'react-icons/fa';
import logo from '../logo.png';
import './Footer.css';
import { useTheme } from '../Theme/ThemeContext';

const Footer = () => {
  const { theme } = useTheme();

  const handleTechieAnmolClick = () => {
    window.open('https://techie-anmol.vercel.app', '_blank');
  };

  return (
    <footer className={`footer ${theme === 'dark' ? 'dark-mode' : ''}`}>
        <br/>
        <br/>

      <div className="footer-content">
        <div className="footer-logo">
          <NavLink to="/">
            <img id="footer-logo-img" src={logo} alt="NDR" />
          </NavLink>
        </div>
        <div className="footer-links">
          <NavLink to="/about" className="footer-link" onClick={() => window.scrollTo(0, 0)}>
            About Us
          </NavLink>
          <NavLink to="/contact" className="footer-link" onClick={() => window.scrollTo(0, 0)}>
            Contact
          </NavLink>
          
        </div>
        <div className="footer-social">
          <a
            href="https://www.youtube.com/@newsdayrajasthan"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-icon"
          >
            <FaYoutube />
          </a>
          <a
            href="https://chat.whatsapp.com/LUPDAf85xbc1tzM79lcSHQ"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-icon"
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
      <div className="footer-copyright">
        &copy; {new Date().getFullYear()} News Day Rajasthan. All rights reserved.
      </div>
      <br />
      <div className="footer-developer" onClick={handleTechieAnmolClick}>
        Developed by Techie Anmol
      </div>
    </footer>
  );
};

export default Footer;
