import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MDBContainer, MDBCol, MDBRow, MDBInput } from "mdb-react-ui-kit";
import axios from "axios";

function ResetPassword() {
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validatePassword = (password) => {
    return /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      setError("Password must have at least one capital letter, one number, and one special character.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.put('/api/auth/password/reset', { resetToken, password });
      setSuccessMessage(response.data.message);
      setPassword('');
      setConfirmPassword('');
      setTimeout(() => {
        navigate('/login');
      }, 5000); // Redirect after 5 seconds
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  return (
    <MDBContainer fluid className="p-3 my-5 custom-container">
    <br/>
    <br/>
    <br/>
    <br/>
      <MDBRow className="justify-content-center align-items-center">
        <MDBCol md="6">
          <form onSubmit={handleResetPassword} className="custom-form">
            <h2 className="text-center mb-4">Reset Password</h2>
            <MDBInput
              wrapperClass="mb-4"
              label="New Password"
              id="password"
              type="password"
              size="lg"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Confirm New Password"
              id="ConfirmPassword"
              type="password"
              size="lg"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
            {error && <p className="text-danger">{error}</p>}
            {successMessage && <p className="text-success">{successMessage}</p>}
            <div className="d-grid gap-2">
              <button
                className="btn btn-primary mb-4 w-100"
                size="lg"
                type="submit"
              >
                Reset Password
              </button>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default ResetPassword;
