import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaSun, FaMoon, FaYoutube, FaWhatsapp } from "react-icons/fa";
import { UserContext } from "../Middleware/UserContext"; // Update path as necessary
import logo from "../logo.png"; // Adjust path based on your project structure
import "./NavigationBar.css";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { useTheme } from "../Theme/ThemeContext"; // Adjust the path based on your project structure

const Navbar = () => {
  const { theme, toggleTheme } = useTheme();
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const { setUser } = useContext(UserContext);
  const [weather, setWeather] = useState(null);
  const [location, setLocation] = useState("Loading..."); // Initial state for location
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMediaIcons(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await axios.get(
          `https://api.open-meteo.com/v1/forecast?latitude=24.8829&longitude=74.6230&current=temperature_2m,wind_speed_10m&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m`
        );
        setWeather(response.data.current);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };
    fetchWeather();
  }, []);

  const toggleMenu = () => {
    setShowMediaIcons((prev) => !prev);
  };

  const handleLogout = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const response = await axios.get("/api/auth/logout");

        if (response.status === 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          setUser(null);
          navigate("/");
        } else {
          console.error("Logout failed:", response.data.message);
        }
      } catch (error) {
        console.error("Logout error:", error);
      }
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <nav className={`main-nav ${theme === "dark" ? "dark-mode" : ""}`} style={{ top: visible ? "0" : "-10vh" }}>
      <NavLink to="/" className="logo">
        <img id="logo-img" src={logo} alt="NDR" />
      </NavLink>
      <div className="header-weather">
        <div className="weather-icon">🌞</div>
        <div className="weather-location">Rajasthan</div>
        {weather && (
          <div className="weather-temperature">
            {weather.temperature_2m}°C
          </div>
        )}
      </div>

      <div
        ref={menuRef}
        className={showMediaIcons ? "menu-link mobile-menu-link active" : "menu-link"}
      >
        <ul className={`navbar-lists ${theme === "dark" ? "dark-mode" : ""}`}>
          <li>
            <NavLink to="/" onClick={toggleMenu} className="svg-wrapper">
              <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
                <rect id="shape" height="40" width="100" />
                <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                  Home
                </text>
              </svg>
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={toggleMenu} className="svg-wrapper">
              <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
                <rect id="shape" height="40" width="100" />
                <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                  About
                </text>
              </svg>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={toggleMenu} className="svg-wrapper">
              <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
                <rect id="shape" height="40" width="100" />
                <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                  Contact
                </text>
              </svg>
            </NavLink>
          </li>
          {user ? (
            <>
              {user.role === "admin" && (
                <>
                  <li>
                    <NavLink to="/admin/manageusers" onClick={toggleMenu} className="svg-wrapper">
                      <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
                        <rect id="shape" height="40" width="100" />
                        <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                          Users
                        </text>
                      </svg>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/managearticles" onClick={toggleMenu} className="svg-wrapper">
                      <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
                        <rect id="shape" height="40" width="100" />
                        <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                          Articles
                        </text>
                      </svg>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/manageads" onClick={toggleMenu} className="svg-wrapper">
                      <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
                        <rect id="shape" height="40" width="100" />
                        <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                          Ads
                        </text>
                      </svg>
                    </NavLink>
                  </li>
                </>
              )}
              {(user.role === "reporter" || user.role === "trusted-reporter") && (
                <li>
                  <NavLink to="/reporter" onClick={toggleMenu} className="svg-wrapper">
                    <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
                      <rect id="shape" height="40" width="100" />
                      <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                        Reporter
                      </text>
                    </svg>
                  </NavLink>
                </li>
              )}
              <li>
                <a onClick={handleLogout} className="svg-wrapper">
                  <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
                    <rect id="shape" height="40" width="100" />
                    <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" >
                      Logout
                    </text>
                  </svg>
                </a>
              </li>
            </>
          ) : (
            <>
              <li>
                <NavLink to="/login" onClick={toggleMenu} className="svg-wrapper">
                  <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
                    <rect id="shape" height="40" width="100" />
                    <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                      Login
                    </text>
                  </svg>
                </NavLink>
              </li>
            </>
          )}
          <li onClick={toggleTheme} className="svg-wrapper">
            <svg height="40" width="100" xmlns="http://www.w3.org/2000/svg">
              <rect id="shape" height="40" width="100" />
              <text className="text2" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" >
              {theme === "dark" ? (
              <>
                <FaSun /> Light Mode
              </>
            ) : (
              <>
                <FaMoon /> Dark Mode
              </>
            )}
              </text>
            </svg>
          </li>
          </ul>
          <div className="social-icons">
          <a href="https://www.youtube.com/@newsdayrajasthan" className="glow-on-hover" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
          <a href="https://chat.whatsapp.com/LUPDAf85xbc1tzM79lcSHQ" className="glow-on-hover" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp />
          </a>
        </div>
      </div>
      
      <div className="mobile-navbar-btn" onClick={toggleMenu}>
        {showMediaIcons ? <AiOutlineClose /> : <GiHamburgerMenu />}
      </div>
    </nav>
  );
};

export default Navbar;
