import React, { useEffect, useState } from "react";
import { Table, Button, Container, Modal, Alert } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { useTheme } from '../Theme/ThemeContext';

const NewsTableSkeleton = () => (
  <tr>
    <td colSpan="3">
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
    </td>
  </tr>
);

const DeletedNews = () => {
  const [DeletedNews, setDeletedNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // State to hold error message
  const { theme } = useTheme();

  useEffect(() => {
    fetchDeletedNews();
  }, []);

  const fetchDeletedNews = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/news/deleted", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedNews = response.data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setDeletedNews(sortedNews);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching deleted news:", error);
      setError("Error fetching deleted news");
    }
  };

  const handleRestoreNews = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axios.patch(
        `/api/news/restore/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedDeletedNews = DeletedNews.filter(
        (article) => article._id !== id
      );
      setDeletedNews(updatedDeletedNews);
    } catch (error) {
      console.error("Error restoring news:", error);
      setError(error.response?.data?.message || "Error restoring news");
    }
  };

  const handleDeleteNews = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `/api/news/permanent-delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedDeletedNews = DeletedNews.filter(
        (article) => article._id !== id
      );
      setDeletedNews(updatedDeletedNews);
    } catch (error) {
      console.error("Error deleting news:", error);
      setError(error.response?.data?.message || "Error deleting news");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNews(null);
  };

  const handleViewDetails = (newsItem) => {
    setSelectedNews(newsItem);
    setShowModal(true);
  };

  return (
    <Container>
      <br />
      <br />
      <br />
      <h2>Deleted News</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {loading ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Article Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <NewsTableSkeleton />
            <NewsTableSkeleton />
            <NewsTableSkeleton />
          </tbody>
        </Table>
      ) : (
        <Table striped bordered hover className={theme === "dark" ? "" : ""}>
          <thead>
            <tr>
              <th>Article details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {DeletedNews.map((article) => (
              <tr key={article._id}>
                <td onClick={() => handleViewDetails(article)}>
                  <h5 style={{ color: 'black' }}>{article.title}</h5>
                  <br />
                  <p style={{ color: 'black' }}>
                    {article.city}, {article.state}
                  </p>
                  <p style={{ color: 'black' }}>{moment(article.date).subtract(5, 'hours').subtract(30, 'minutes').format("DD-MM-YYYY, h:mm A")}</p>
                  <br />
                  {article.reporter.username}
                  <hr />
                </td>
                <td style={{ width: '10%' }}>
                  <Button
                    className="mt-2 me-2"
                    variant="success"
                    onClick={() => handleRestoreNews(article._id)}
                  >
                    Restore
                  </Button>{" "}
                  <Button
                    className="mt-2 me-2"
                    variant="danger"
                    onClick={() => handleDeleteNews(article._id)}
                  >
                    Delete Permanently
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton className={theme === "dark" ? "modal-dark" : ""}>
          <Modal.Title>Article Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className={theme === "dark" ? "modal-dark" : ""}>
          {selectedNews && (
            <div>
              <h6 style={{ color: 'red' }}>{selectedNews.views} views</h6>
              <p>{selectedNews.status}</p>
              <h2>{selectedNews.title}</h2>
              <h6>{selectedNews.reporter.username}</h6>
              <p>{moment(selectedNews.date).format("Do MMMM YYYY")}</p>
              <p>{selectedNews.content}</p>
              <p>City: {selectedNews.city}</p>
              <p>State: {selectedNews.state}</p>
              {selectedNews.images && (
                <div>
                  <h5>Images</h5>
                  {selectedNews.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ maxWidth: "100%", marginBottom: "10px" }}
                    />
                  ))}
                </div>
              )}
              {selectedNews.youtubeLink && (
                <div>
                  <h5>Youtube link</h5>
                  <p>{selectedNews.youtubeLink}</p>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={theme === "dark" ? "modal-dark" : ""}>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DeletedNews;
