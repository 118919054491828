import React from 'react';
import './AboutPage.css';
import { useTheme } from '../Theme/ThemeContext'; // Adjust the path based on your project structure

const AboutPage = () => {
    const { theme } = useTheme();

    return (
        <div className={`about-container ${theme === 'dark' ? 'dark-mode' : ''}`}>
    <br/>
    <br/>
    <br/>

            <h1>न्यूज डे राजस्थान में आपका स्वागत है</h1>
            <p>
                न्यूज डे राजस्थान, जो नवीनतम समाचार लेखों के लिए आपका नंबर एक स्रोत है। हम आपको सबसे विश्वसनीय, सटीक और अद्यतित जानकारी के साथ नवीनतम समाचार प्रदान करने के लिए प्रतिबद्ध हैं।
            </p>
            <p>
                2023 में स्थापित, न्यूज डे राजस्थान ने एक लंबा सफर तय किया है। जब हमने पहली बार शुरुआत की, तो समाचार प्रदान करने के हमारे जुनून ने हमें अपना खुद का समाचार मंच शुरू करने के लिए प्रेरित किया।
            </p>
            <p>
                हमें उम्मीद है कि आपको हमारे समाचार लेख उतने ही पसंद आएंगे जितना हमें उन्हें आपको प्रदान करने में आनंद आता है। यदि आपके कोई प्रश्न या टिप्पणियाँ हैं, तो कृपया हमसे संपर्क करने में संकोच न करें।
            </p>
            <p>
                सादर,
                <br />
                न्यूज डे राजस्थान टीम
            </p>
            <br/>
            <br/>
            <br/>
            <h1>संस्थापक के बारे में</h1>
            <p>
                न्यूज डे राजस्थान की स्थापना <b>विनोद शर्मा</b> द्वारा की गई है, जो चित्तौड़गढ़, राजस्थान के एक प्रतिष्ठित रिपोर्टर हैं। उनके अनुभव और समर्पण ने इस मंच को संभव बनाया है।
            </p>
        </div>
    );
};

export default AboutPage;
