import React, { useState } from 'react';
import './ContactPage.css';
import axios from 'axios';

const ContactPage = ({ theme }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState('');

  const validateForm = () => {
    if (!name || !email || !mobile || !message) {
      setValidationError('सभी फ़ील्ड्स आवश्यक हैं।');
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setValidationError('कृपया मान्य ईमेल दर्ज करें।');
      return false;
    }

    const mobilePattern = /^[0-9]{10}$/;
    if (!mobilePattern.test(mobile)) {
      setValidationError('कृपया मान्य मोबाइल नंबर दर्ज करें। (10 अंकों का)');
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidationError('');

    if (!validateForm()) {
      return;
    }

    axios.post("/api/user/contact", { name, email, mobile, message })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setSubmitted(true);
          setError('');
        } else {
          throw new Error('Failed to send email');
        }
      })
      .catch((error) => {
        setError('Failed to send email');
      });
  };

  return (
    <div className={theme === "dark" ? "dark-mode" : ""}>
      <br />
      <br />
      <br />
      <br />
      <br />

      <h1>हमसे संपर्क करें</h1>
      <p>यदि आपके कोई प्रश्न, टिप्पणियाँ,सुझाव हैं, या कोई समाचार भेजना चाहते है तो कृपया नीचे दिए गए फॉर्म के माध्यम से हमसे संपर्क करें।</p>

      {submitted ? (
        <p>धन्यवाद! आपका संदेश प्राप्त हो गया है।</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" aria-label="Name">नाम:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              aria-required="true"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" aria-label="Email">ईमेल:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-required="true"
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobile" aria-label="Mobile">मोबाइल नंबर:</label>
            <input
              type="tel"
              id="mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
              aria-required="true"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message" aria-label="Message">संदेश:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              aria-required="true"
            ></textarea>
          </div>
          <button type="submit" role="button">भेजें</button>
        </form>
      )}
      {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default ContactPage;
