import React, { useEffect, useState } from "react";
import { Table, Button, Container, Modal } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Register from "../Auth/Register";

// Skeleton Loader Component for User Table Rows
const UserTableSkeleton = () => (
  <tr>
    <td colSpan="2">
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
    </td>
  </tr>
);

// Skeleton Loader Component for User Articles Modal
const UserArticlesSkeleton = () => (
  <tr>
    <td colSpan="3">
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
    </td>
  </tr>
);

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [userArticles, setUserArticles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/user/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchUserArticles = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`/api/news/author/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserArticles(response.data);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching user articles:", error);
    }
  };

  const handleToggleUserStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === "active" ? "suspended" : "active";
    try {
      await axios.patch(
        `/api/user/${newStatus}/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const updatedUsers = users.map((user) => {
        if (user._id === id) {
          return { ...user, status: newStatus };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handleChangeUserRole = async (id, currentRole) => {
    const newRole = currentRole === "reporter" ? "trusted-reporter" : "reporter";
    try {
      await axios.patch(
        `/api/user/${newRole}/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const updatedUsers = users.map((user) => {
        if (user._id === id) {
          return { ...user, role: newRole };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  const handleDeleteArticle = async (id) => {
    try {
      await axios.delete(`/api/news/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setUserArticles(userArticles.filter((article) => article._id !== id));
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  return (
    <Container>
      <br/>
      <br/>
      <br/>
      <Link to="/admin/newuserregister">
        <Button className="custom-btn btn-5">Add Users</Button>
      </Link>
      <h1 className="my-4">All Users</h1>
      {loading ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>User's Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <UserTableSkeleton />
            <UserTableSkeleton />
            <UserTableSkeleton />
          </tbody>
        </Table>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>User's Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td style={{ width: '70%' }}>
                  {user.username}
                  <br />
                  {user.email}
                  <br />
                  {user.role}
                  <br />
                  {user.status}
                </td>
                <td style={{ width: '10%' }}>
                  <Button style={{ margin: "0.5rem 0" }}
                    variant={user.status === "active" ? "danger" : "success"}
                    onClick={() => handleToggleUserStatus(user._id, user.status)}
                  >
                    {user.status === "active" ? "Suspend Account" : "Activate Account"}
                  </Button>
                  <Button style={{ margin: "0.5rem 0" }}
                    variant="primary"
                    onClick={() => {
                      fetchUserArticles(user._id);
                      setUsername(user.username);
                    }}
                  >
                    Articles
                  </Button>
                  <Button style={{ margin: "0.5rem 0" }}
                    variant={user.role === "reporter" ? "info" : "warning"}
                    onClick={() => handleChangeUserRole(user._id, user.role)}
                  >
                    {user.role === "reporter" ? "Make Trusted-Reporter" : "Make Reporter"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{username}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userArticles.length === 0 && !loading ? (
            <p>No articles found.</p>
          ) : loading ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Content</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <UserArticlesSkeleton />
                <UserArticlesSkeleton />
                <UserArticlesSkeleton />
              </tbody>
            </Table>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Content</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userArticles.map((article) => (
                  <tr key={article._id}>
                    <td>{article.title}</td>
                    <td>{article.content}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteArticle(article._id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AdminUsers;
