import React, { useState, useEffect } from "react";
import { MDBContainer, MDBCol, MDBRow, MDBInput } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BiHide, BiShow } from "react-icons/bi"; // Import icons for toggle

function Register() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [registrationError, setRegistrationError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for toggling confirm password visibility

  useEffect(() => {
    if (message === 'User registered successfully') {
      const timer = setTimeout(() => {
        navigate('/login');
      }, 5000); // Redirect after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [message, navigate]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setEmailError(''); // Clear email error message
    const isValidEmail = /\S+@\S+\.\S+/.test(value);
    if (!isValidEmail && value.trim() !== '') {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setPasswordError(''); // Clear password error message
    const isValidPassword =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value);
    if (!isValidPassword && value.trim() !== '') {
      setPasswordError(
        'Password must have at least one capital letter, one number, and one special character.'
      );
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidEmail = /\S+@\S+\.\S+/.test(email);
    const isValidPassword =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password
      );
    const passwordsMatch = password === confirmPassword;

    if (!isValidEmail) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    if (!isValidPassword) {
      setPasswordError(
        "Password must have at least one capital letter, one number, and one special character."
      );
      return;
    }
    if (!passwordsMatch) {
      setPasswordError("Passwords do not match.");
      return;
    }

    const formData = {
      username,
      email,
      password
    };

    try {
      const response = await axios.post('/api/auth/register', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setMessage('User registered successfully');

      // Clear form data
      setUsername('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setEmailError('');
      setPasswordError('');
      setRegistrationError('');

    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || 'Error registering user';
        if (error.response.status === 413) {
          setRegistrationError('Image size is too large. Please upload an image smaller than 1MB.');
        } else if (errorMessage.includes('E11000')) {
          setRegistrationError('This email is already registered. Please use a different email.');
        } else {
          setRegistrationError(errorMessage);
        }
        console.error(`Registration Error: ${errorMessage}`);
      } else {
        const fallbackMessage = 'An unexpected error occurred during registration. Please try again later.';
        setRegistrationError(fallbackMessage);
        console.error(`Registration Error: ${error.message || fallbackMessage}`);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  const handleLoginClick = () => {
    navigate('/login'); // Navigate to the login page
  };

  return (
    <MDBContainer fluid className="p-3 my-5">
    <br/>
    <br/>
    <br/>
  
      <MDBRow>
        <MDBCol col="10" md="6">
          <img
            src="https://res.cloudinary.com/dhhztw6hx/image/upload/v1719333628/xy0kucwbfo8vfmx8bxz8.png"
            className="img-fluid"
            alt="Phone drawing"
          />
        </MDBCol>
        <MDBCol col="4" md="6">
          <h3 className="mb-4">Create an account</h3>
          <form onSubmit={handleSubmit}>
            <MDBInput
              wrapperClass="mb-4"
              label="Name"
              id="formControlLg"
              type="text"
              size="lg"
              value={username}
              onChange={handleUsernameChange}
              required
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Email"
              id="formControlLg"
              type="email"
              size="lg"
              value={email}
              onChange={handleEmailChange}
              required
            />
            {emailError && <p className="text-danger">{emailError}</p>}
            <MDBInput
              wrapperClass="mb-4"
              label="Password"
              id="formControlLg"
              type={showPassword ? "text" : "password"} // Toggle visibility based on state
              size="lg"
              value={password}
              onChange={handlePasswordChange}
              required
              append={
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <BiHide /> : <BiShow />}
                </button>
              }
            />
            {passwordError && <p className="text-danger">{passwordError}</p>}
            <MDBInput
              wrapperClass="mb-4"
              label="Confirm Password"
              id="formControlLg"
              type={showConfirmPassword ? "text" : "password"} // Toggle visibility based on state
              size="lg"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              append={
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? <BiHide /> : <BiShow />}
                </button>
              }
            />
            {!passwordError && password !== confirmPassword && (
              <p className="text-danger">Passwords do not match.</p>
            )}
            
            <div className="d-grid gap-2">
              <button className="btn btn-primary mb-4" size="lg" type="submit">
                Register
              </button>
            </div>
          </form>
          {message && <p className="text-success">{message}</p>}
          {registrationError && (
            <p className="text-danger">{registrationError}</p>
          )}
          <div className="divider d-flex align-items-center my-4">
            <p className="text-center fw-bold mx-3 mb-0">
              Already have an account?
            </p>
            <button
              className="btn btn-primary"
              onClick={handleLoginClick}
              style={{ backgroundColor: "#3b5998" }}
            >
              Login Here
            </button>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Register;
