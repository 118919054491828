import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from '../Theme/ThemeContext'; 

import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import share from '../Middleware/share';
import './NewsDetailPage.css'; 
import NewsTitlesBulletLine from '../BulletLine/NewsTitlesBulletLine'; 
import AdList from '../Ads/AdList';
import ErrorPage from "../Error/ErrorPage";
import logo from '../logo.png';

const NewsDetailPage = () => {
  const [article, setArticle] = useState(null);
  const [message, setMessage] = useState("");
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const youtubePlayerRef = useRef(null);
  const { theme } = useTheme();

  document.body.classList.toggle('dark-mode', theme==='dark');

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/api/news/${id}`);
        setArticle(response.data);
      } catch (error) {
        console.error("Error fetching the article:", error);
        setMessage("समाचार उपलब्ध नहीं है");
      }
    };

    fetchArticle();
  }, [id]);

  useEffect(() => {
    if (article) {
      document.title = article.title;
    }
  }, [article]);

  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault();
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('/api/news/all');
        const sortedNews = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNews(sortedNews);
        setLoading(false);
      } catch (error) {
        console.error('There was an error fetching the news!', error);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    if (article && youtubePlayerRef.current) {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/platform.js";
      script.async = true;
      script.onload = () => {
        window.gapi.load("subscribe", () => {
          new window.gapi.youtube.subscribe.SubscribeButton({
            channel: article.youtubeChannelId,
            layout: "default",
            theme: "default",
            size: "default",
            count: "default",
            iframe: youtubePlayerRef.current,
          });
        });
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [article]);

  const getEmbedUrl = (youtubeLink) => {
    try {
      const url = new URL(youtubeLink);
      let videoId;

      if (url.hostname === "youtu.be") {
        videoId = url.pathname.slice(1);
      } else if (
        url.hostname === "www.youtube.com" ||
        url.hostname === "youtube.com"
      ) {
        videoId = url.searchParams.get("v");
      } else {
        throw new Error("Invalid YouTube URL");
      }

      if (!videoId) throw new Error("Invalid YouTube URL");
      return `https://www.youtube.com/embed/${videoId}`;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubscribeClick = () => {
    window.open(`https://www.youtube.com/@newsdayrajasthan`, "_blank");
  };

  const handleHomePageClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderYouTubeVideo = () => {
    if (article && article.youtubeLink) {
      return (
        <div className="youtube-video">
          {getEmbedUrl(article.youtubeLink) && (
            <iframe
              className="embed-responsive-item"
              src={getEmbedUrl(article.youtubeLink)}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              ref={youtubePlayerRef}
            ></iframe>
          )}
        </div>
      );
    }
    return null;
  };

 


  if (!article) {
    return (
      <div className="container text-center mt-4">
        <Skeleton height={50} width={`80%`} />
        <br />
        <Skeleton height={300} />
        <br />
        <Skeleton height={30} width={200} />
        <br />
        <Skeleton height={20} width={150} />
        <br />
        <Skeleton height={20} width={`60%`} count={3} />
        <br />
        <Skeleton height={50} width={200} />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Filter out the current article from recommended news
  const recommendedNews = news.filter(item => item._id !== id);

  return (
    <div className="container mt-4 text-center no-copy">
      <br/>
      <NewsTitlesBulletLine news={news} />
      <br />
      <AdList />
      <div className="mt-4 text-center">
      <hr/>
        <br />
        <h1 className="heading" style={{fontSize:"25px"}}>{article.title}</h1>

        {article.images.length > 1 ? (
          <Slider {...settings}>
            {article.images.map((image, index) => (
              <div key={`${image}-${index}`} className="image-container">
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="img-fluid mb-3 mx-auto d-block image"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "default-image.jpg";
                  }}
                />
                <div className="watermark-overlay">
                  <span className="watermark-text">
                    News Day Rajasthan
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="image-container">
            <img
              src={article.images[0]}
              alt=""
              className="img-fluid mb-3 mx-auto d-block image watermark-container"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "default-image.jpg";
              }}
            />
            <div className="watermark-overlay">
              <span className="watermark-text">
                News Day Rajasthan
              </span>
            </div>
          </div>
        )}
      </div>

      <button
        className="btn btn-primary button-share mt-3"
        onClick={() => share(article)}
      >
        <FontAwesomeIcon icon={faShareAlt} /> शेयर करे
      </button>

      <br />
      <h6 className="fs-5 text-start">{moment(article.date).subtract(5, 'hours').subtract(30, 'minutes').format("DD-MM-YYYY")}</h6>
      <h5 className="fs-5 text-start">{article.city || "चित्तौड़गढ़"}</h5>
      <p>
        <h5 className="fs-5 text-start">पत्रकार {article.reporter.username}</h5>
      </p>

      <div className="mx-auto text-start preformatted-text">
        <p className="fs-4 text-start">
          {article.content.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>

      <div className="youtube">{renderYouTubeVideo()}</div>

      <button className="btn btn-primary mt-3" onClick={handleSubscribeClick}>
        हमरे यूट्यूब चैनल को सबस्क्राइब करे..!!
      </button>

      <div onClick={handleHomePageClick}>
        <br />
        <hr />
        <h1>और समाचार पढे</h1>

        <div className="row">
          {recommendedNews.slice(0, 15).map((article) => (
            <div className="col-md-4" key={article._id}>
              <Link to={`/news/${article._id}`} className="news-card">
                {article.images && article.images.length > 0 && (
                  <div className="image-container">
                    <img
                      src={`${article.images[0]}`}
                      alt={article.title}
                      className="card-image"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "default-image.jpg";
                      }}
                    />
                    <div className="watermark-overlay">
                      <span className="watermark-text">
                        News Day Rajasthan
                      </span>
                    </div>
                  </div>
                )}
                <div className="card-details">
                  <h2 className="card-title">{article.title}</h2>
                  <h6 className="card-date">
                    {moment(article.date).subtract(5, 'hours').subtract(30, 'minutes').format("DD-MM-YYYY")}
                  </h6>
                  <p className="card-location">{article.city}, {article.state}</p>
                  <Link to={`/news/${article._id}`} className="read-more">
                    ...पढ़ना जारी रखे
                  </Link>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsDetailPage;
