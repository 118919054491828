import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

const ProtectedRoute = ({ element, allowedRoles }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const verifyAuth = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setLoading(false);
        setRedirect(true);
        return;
      }

      try {
        const response = await axios.get("/api/auth/check-auth", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const userRole = response.data.user.role; // Adjust according to your API response structure
          if (allowedRoles.includes(userRole)) {
            setIsAuthenticated(true);
          } else {
            setRedirect(true);
          }
        } else {
          setRedirect(true);
        }
      } catch (error) {
        console.error("Authentication verification error:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setRedirect(true);
      } finally {
        setLoading(false);
      }
    };

    verifyAuth();
  }, [allowedRoles]);

  if (loading) {
    return <div>Loading...</div>; // Optional: Show a loading indicator
  }

  if (redirect) {
    return <Navigate to="/login" />;
  }

  if (isAuthenticated) {
    return element;
  }

  return <Navigate to="/login" />;
};

export default ProtectedRoute;
