import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ad.css';

const AdList = () => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    axios.get('/api/ads/all')
      .then(response => {
        setAds(response.data);
      })
      .catch(error => {
        console.error('Error fetching ads:', error);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='ad-session'>
    <br/>

      <Slider {...settings}>
        {ads.map(ad => (
          <div key={ad._id}>
            {/* <a href={ad.link} target="_blank" rel="noopener noreferrer"> */}
              <img className="ad-img" src={Array.isArray(ad.images) ? ad.images[0] : ad.images} alt="ad" />
            {/* </a> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AdList;

// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import './ad.css';

// const AdList = () => {
//   const [ads, setAds] = useState([]);
//   const imgContainerRef = useRef(null);

//   useEffect(() => {
//     axios.get('/api/ads/all')
//       .then(response => {
//         setAds(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching ads:', error);
//       });
//   }, []);

//   useEffect(() => {
//     const imgContainer = imgContainerRef.current;
//     if (imgContainer && ads.length > 0) {
//       const interval = setInterval(() => {
//         const firstChild = imgContainer.firstElementChild;
//         if (firstChild) {
//           imgContainer.appendChild(firstChild);
//         }
//       }, 1500);

//       return () => clearInterval(interval); // Cleanup interval on unmount
//     }
//   }, [ads]);

//   return (
//     <div className="img-container" ref={imgContainerRef}>
//       {ads.map((ad, index) => (
//         <div className="box" key={index}>
//           <a href={ad.link} target="_blank" rel="noopener noreferrer">
//             <img className="ad-img" src={Array.isArray(ad.images) ? ad.images[0] : ad.images} alt="ad" />
//           </a>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default AdList;

