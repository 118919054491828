import React, { useState } from "react";
import { Container, Modal, Button, Row, Col } from "react-bootstrap";
import moment from "moment";

import PendingNews from "./PendingNews";
import ApprovedNews from "./ApprovedNews";
import DeletedNews from "./DeletedNews";
import { useTheme } from "../Theme/ThemeContext";
import './AdminNews.css';

const AdminNews = () => {
  const [selectedNews, setSelectedNews] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [view, setView] = useState("approved"); // state to track which view is active
  const { theme } = useTheme();

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNews(null);
  };

  const handleViewDetails = (newsItem) => {
    setSelectedNews(newsItem);
    setShowModal(true);
  };

  return (
    <Container>
    <br/>
    <br/>
    <br/>
    <br/>
      <h1 className="my-4">Admin News Management</h1>
      <Row className="mb-4">
        <Col>
          <Button variant="primary" className="custom-btn btn-6" onClick={() => setView("approved")}>
            View Approved News
          </Button>
        </Col>
        <Col>
          <Button variant="secondary" className="custom-btn btn-6" onClick={() => setView("pending")}>
            View Pending News
          </Button>
        </Col>
        <Col>
          <Button variant="danger" className="custom-btn btn-6" onClick={() => setView("deleted")}>
            View Deleted News
          </Button>
        </Col>
      </Row>
      {view === "approved" && <ApprovedNews handleViewDetails={handleViewDetails} />}
      {view === "pending" && <PendingNews handleViewDetails={handleViewDetails} />}
      {view === "deleted" && <DeletedNews handleViewDetails={handleViewDetails} />}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton className={theme === "dark" ? "modal-dark" : ""}>
          <Modal.Title>Article Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className={theme === "dark" ? "modal-dark" : ""}>
          {selectedNews && (
            <div>
              <h6 style={{ color: 'red' }}>{selectedNews.views} views</h6>
              <p>{selectedNews.status}</p>
              <h2>{selectedNews.title}</h2>
              <h6>{selectedNews.reporter.username}</h6>
              <p>{moment(selectedNews.date).format("Do MMMM YYYY")}</p>
              <p>{selectedNews.content}</p>
              <p>City: {selectedNews.city}</p>
              <p>State: {selectedNews.state}</p>
              {selectedNews.images && (
                <div>
                  <h5>Images</h5>
                  {selectedNews.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ maxWidth: "100%", marginBottom: "10px" }}
                    />
                  ))}
                </div>
              )}
              {selectedNews.youtubeLink && (
                <div>
                  <h5>Youtube link</h5>
                  <p>{selectedNews.youtubeLink}</p>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={theme === "dark" ? "modal-dark" : ""}>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AdminNews;
