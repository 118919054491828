import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBCol, MDBRow, MDBInput } from 'mdb-react-ui-kit';
import axios from 'axios';
import { UserContext } from '../Middleware/UserContext';

const Login = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', { email, password });
      const { token, user } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      setUser(user);

      if (user.role === 'admin') {
        window.scrollTo(0, 0);
        navigate('/');
      } else if (user.role === 'reporter' || user.role === 'trusted-reporter') {
        window.scrollTo(0, 0);
        navigate('/reporter');
      } else {
        setMessage('Invalid role received');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === 'Your account is not active') {
          setMessage('Your account is not active. Please contact admin.');
        } else {
          setMessage('Invalid credentials');
        }
      } else if (error.request) {
        setMessage('Error sending request');
      } else {
        setMessage('Error sending request');
      }
    }
  };

  const handleForgetPassword = () => {
    navigate('/forgotpassword');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <MDBContainer fluid className="p-3 my-5 custom-container">
    <br/>
    <br/>
    <br/>
      <MDBRow className="justify-content-center align-items-center">
        <MDBCol md="6">
          <form onSubmit={handleLogin} className="custom-form">
            <h2 className="text-center mb-4">Login</h2>
            <MDBInput
              wrapperClass="mb-4"
              label="Email address"
              id="formControlLg"
              type="email"
              size="lg"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Password"
              id="formControlLg"
              type={showPassword ? "text" : "password"}
              size="lg"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="d-flex justify-content-between align-items-center mb-4">
              <button
                type="button"
                style={{width:"150px",height:"60px"}}
                onClick={handleForgetPassword}
                className="custom-btn btn-5 "
              >
                Forgot password?
              </button>
              <button
                type="button"
                className="custom-btn btn-3 "
                style={{width:"120px"}}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Hide" : "Show"} Password
              </button>
            </div>
            {message && <p className="text-danger text-center">{message}</p>}
            <div className="d-grid gap-2 ">
              <button
                className="custom-btn btn-7"
                size="lg"
                type="submit"
              >
                Login
              </button>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Login;
