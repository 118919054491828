import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/Home/HomePage";
import AboutPage from "./components/About/About";
import ContactPage from "./components/ContactPage/ContactPage";
import LoginPage from "./components/Auth/Login";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import NewsDetailPage from "./components/NewsDetail/NewsDetailPage";
import AdminNews from "./components/Admin/AdminNews";
import AdminUsers from "./components/Admin/AdminUsers";
import AdminAdsDashboard from "./components/Admin/AdForm";
import ErrorPage from "./components/Error/ErrorPage";
import Register from "./components/Auth/Register";
import ReporterDashboard from "./components/Reporter/ReporterDashboard";
import ProtectedRoute from "./ProtectedRoute";
import './components/navbar/Navbar';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/about" element={<AboutPage />} />
    <Route path="/contact" element={<ContactPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/forgotpassword" element={<ForgotPassword />} />
    <Route path="/password/reset/:resetToken" element={<ResetPassword />} />
    <Route path="/news/:id" element={<NewsDetailPage />} />
    <Route path="/admin/manageusers" element={<ProtectedRoute element={<AdminUsers />} allowedRoles={["admin"]} />} />
    <Route path="/admin/managearticles" element={<ProtectedRoute element={<AdminNews />} allowedRoles={["admin"]} />} />
    <Route path="/admin/manageads" element={<ProtectedRoute element={<AdminAdsDashboard />} allowedRoles={["admin"]} />} />
    <Route path="/admin/newuserregister" element={<ProtectedRoute element={<Register />} allowedRoles={["admin"]} />} />
    <Route path="/reporter" element={<ProtectedRoute element={<ReporterDashboard />} allowedRoles={["reporter", "trusted-reporter"]} />} />
    <Route path="*" element={<ErrorPage />} />
  </Routes>
);

export default AppRoutes;
