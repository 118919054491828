import React, { useEffect, useState } from "react";
import { Table, Button, Container, Form, Collapse, Modal, Row, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import 'moment-timezone';
import './adForm.css'
import { useTheme } from '../Theme/ThemeContext';

const AdminAdsDashboard = () => {
  const [ads, setAds] = useState([]);
  const [form, setForm] = useState({
    title: "",
    description: "",
    link: "",
    marketedBy: "",
    expiresAt: "",
  });
  const [files, setFiles] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const {theme} = useTheme();

  useEffect(() => {
    fetchAds();
  }, []);

 
  const fetchAds = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      const response = await axios.get("/api/ads/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sortedAds = response.data.sort(
        (a, b) => new Date(b.expiresAt).getTime() - new Date(a.expiresAt).getTime()
      );

      setAds(sortedAds);
    } catch (error) {
      console.error("Error fetching ads:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = editMode ? `/api/ads/update/${editId}` : "/api/ads/create";
    const data = new FormData();

    data.append("title", form.title);
    data.append("description", form.description);
    data.append("link", form.link);
    data.append("marketedBy", form.marketedBy);
    data.append("expiresAt", form.expiresAt);

    const currentDate = moment().format('YYYY-MM-DD');
    const currentTime = moment().format('HH:mm');
    const dateTimeString = `${currentDate} ${currentTime}`;
    data.append("createdAt", dateTimeString);

    Array.from(files).forEach((file) => data.append("images", file));

    try {
      setLoading(true);
      const response = await axios({
        method: editMode ? "put" : "post",
        url: url,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const updatedAds = editMode
        ? ads.map((item) => (item._id === editId ? response.data.ad : item))
        : [response.data.ad, ...ads];

      setAds(updatedAds.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));

      setForm({
        title: "",
        description: "",
        link: "",
        marketedBy: "",
        expiresAt: "",
        files: "",
      });
      setFiles([]);
      setEditMode(false);
      setEditId(null);
    } catch (error) {
      console.error("There was an error creating/updating the ad!", error);

      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message === "File too large"
      ) {
        alert("File size is too large. Please upload files smaller than 5MB.");
      } else {
        alert(
          "There was an error creating/updating the ad. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (adItem) => {
    setForm(adItem);
    setEditMode(true);
    setEditId(adItem._id);
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this ad?");
    if (!confirmed) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      await axios.delete(`/api/ads/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedAds = ads.filter((item) => item._id !== id);
      setAds(updatedAds);
    } catch (error) {
      console.error("Error deleting ad:", error);

      if (error.response) {
        if (error.response.status === 404) {
          alert("Ad not found. It may have already been deleted.");
        } else {
          alert("Error deleting ad. Please try again later.");
        }
      } else {
        alert("Network error deleting ad. Please check your internet connection.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAd(null);
  };

  const handleViewDetails = (adItem) => {
    setSelectedAd(adItem);
    setShowModal(true);
  };


  return (
    <Container className={theme === "dark" ? "container-dark" : "container-custom"}>
    <br/>
    <br/>
    <br/>
    <br/>

      <Form className={theme === "dark" ? "form-dark" : "form-custom"} onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formTitle">
              <Form.Label><h4>Title</h4></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={form.title}
                onChange={(e) => setForm({ ...form, title: e.target.value })}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formLink">
              <Form.Label><h4>Link</h4></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter link"
                value={form.link}
                onChange={(e) => setForm({ ...form, link: e.target.value })}
              />
            </Form.Group>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formMarketedBy">
              <Form.Label><h4>Marketed By</h4></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter marketed by"
                value={form.marketedBy}
                onChange={(e) => setForm({ ...form, marketedBy: e.target.value })}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formExpiresAt">
              <Form.Label><h4>Expires At</h4></Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter expiration date"
                value={form.expiresAt}
                onChange={(e) => setForm({ ...form, expiresAt: e.target.value })}
              />
            </Form.Group>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formDescription">
              <Form.Label><h4>Description</h4></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                value={form.description}
                onChange={(e) => setForm({ ...form, description: e.target.value })}
              />
            </Form.Group>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formImage">
              <Form.Label><h4>Upload Image</h4></Form.Label>
              <Form.Control type="file" onChange={handleFileChange} multiple />
            </Form.Group>
          </Col>
        </Row>
        <br></br>

        <Button
          variant="primary"
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                animation="border"
                size="sm"
                className="mr-2"
              />
              Saving...
            </>
          ) : editMode ? "Update Ad" : "Create Ad"}
        </Button>
      </Form>

      <br /><br />

      <Table striped bordered hover className={theme === "dark" ? "" : ""}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Marketed By</th>
            <th>Expires At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {ads.map((adItem, index) => (
            <React.Fragment key={adItem._id}>
              <tr >
                <td onClick={() => handleViewDetails(adItem)}>{adItem.title}</td>
                <td onClick={() => handleViewDetails(adItem)}>{adItem.marketedBy}</td>
                <td onClick={() => handleViewDetails(adItem)}>{moment(adItem.expiresAt).format("DD/MM/YYYY")}</td>
                <td style={{ width: '10%'}}>
                  <Button style={{margin:"0.5rem 0"}}
                    variant="warning"
                    size="sm"
                    className="mr-2"
                    onClick={() => handleEdit(adItem)}>
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(adItem._id)}
                    >
                    Delete
                  </Button>
                  </td>
              </tr>
            
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className={theme === "dark" ? "modal-dark" : ""} >
          <Modal.Title>Ad Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className={theme === "dark" ? "modal-dark" : ""}>
          {selectedAd && (
            <div>
              <h5>Title</h5>
              <p>{selectedAd.title}</p>
              <h5>Description</h5>
              <p>{selectedAd.description}</p>
              <h5>Link</h5>
              <p>{selectedAd.link}</p>
              <h5>Marketed By</h5>
              <p>{selectedAd.marketedBy}</p>
              <h5>Expires At</h5>
              <p>{moment(selectedAd.expiresAt).format("DD/MM/YYYY")}</p>
              <h5>Images</h5>
              {selectedAd.images && selectedAd.images.length > 0 ? (
                selectedAd.images.map((image, index) => (
                  <img
                      key={index}
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ maxWidth: "100%", marginBottom: "10px" }}
                    />
                ))
              ) : (
                <p>No images available</p>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={theme === "dark" ? "modal-dark" : ""}>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AdminAdsDashboard;
