// UserContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a UserContext
const UserContext = createContext();

// Create a UserProvider component to wrap your app with
const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

// Create a custom hook to use the UserContext conveniently
const useUser = () => useContext(UserContext);

export { UserContext, UserProvider, useUser };
