import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBInput } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const protocol = window.location.protocol;
  const host = window.location.host;
  const baseURL = `${protocol}//${host}`;

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/auth/password/forgot', { email, baseURL });
      setMessage('Reset Password Link successfully sent to ' + email);
    } catch (error) {
      console.error('Error:', error.response?.data?.message || error.message);
      if (error.response?.status === 401) {
        setMessage('Invalid Email address.');
      } else if (error.response?.status === 404) {
        setMessage('No user found with this Email address: ' + email);
      } else {
        setMessage('An error occurred during sending mail.');
      }
    }
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <MDBContainer fluid className="p-3 my-5 custom-container">
    <br/>
    <br/>
    <br/>
    <br/>
      <MDBRow className="justify-content-center align-items-center">
        <MDBCol md="6">
          <form onSubmit={handleForgetPassword} className="custom-form">
            <h2 className="text-center mb-4">Forgot Password</h2>
            <MDBInput 
              wrapperClass="mb-4" 
              label="Email address" 
              id="formControlLg" 
              type="email" 
              size="lg" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
            <div className="d-grid gap-2">
              <button className="btn btn-primary mb-4 w-100" size="lg" type="submit">Send Reset Link</button>
            </div>
            {message && <p className="text-success">{message}</p>}
            <div className="text-center mt-4">
              <p className="fw-bold mb-0">Remembered your password?</p>
              <button className="custom-btn btn-14" onClick={handleLoginClick}>Login Here</button>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default ForgotPassword;
