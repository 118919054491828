import React from "react";

const NewsTableSkeleton = () => (
  <tr>
    <td colSpan="3">
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
    </td>
  </tr>
);

export default NewsTableSkeleton;
